 @font-face {
     font-family: Raleway;
     src: url(assets/font/Raleway/Raleway-Regular.ttf) format("opentype");
 }

 @font-face {
     font-family: Roboto;
     src: url(assets/font/Roboto/Roboto-Regular.ttf) format("opentype");
 }

 @font-face {
     font-family: QuickSandBold;
     src: url(assets/font/Quicksand/static/Quicksand-Bold.ttf) format("opentype");
 }

 @font-face {
     font-family: QuickSandLight;
     src: url(assets/font/Quicksand/static/Quicksand-Light.ttf) format("opentype");
 }

 @font-face {
     font-family: QuickSandRegular;
     src: url(assets/font/Quicksand/static/Quicksand-Regular.ttf) format("opentype");
 }

 @font-face {
     font-family: QuickSandSemiBold;
     src: url(assets/font/Quicksand/static/Quicksand-SemiBold.ttf) format("opentype");
 }

 @font-face {
     font-family: QuickSandMedium;
     src: url(assets/font/Quicksand/static/Quicksand-Medium.ttf) format("opentype");
 }

 @font-face {
     font-family: ComfortaaRegular;
     src: url(assets/font/Comfortaa/static/Comfortaa-Regular.ttf) format("opentype");
 }

 @font-face {
     font-family: GothamRoundedBook;
     src: url(assets/font/gotham-rounded/GothamRounded-Book.otf) format("opentype");
 }

 @font-face {
     font-family: LibreBaskervilleItallic;
     src: url(assets/font/Libre_Baskerville/LibreBaskerville-Italic.ttf) format("opentype");
 }

 @font-face {
     font-family: Parisienne;
     src: url(assets/font/Parisienne/Parisienne-Regular.ttf) format("opentype");
 }

 @font-face {
     font-family: 'CrimsonText', serif;
     src: url(assets/font/Crimson_Text/CrimsonText-Italic.ttf) format("opentype");
 }

 @font-face {
     font-family: 'Civilian';
     src: url(assets/font/Civilian/Civilian.ttf) format("opentype");
 }

 @font-face {
     font-family: 'SpectralMedium';
     src: url(assets/font/Spectral/Spectral-MediumItalic.ttf) format("opentype");
 }

 @font-face {
     font-family: 'baskervilleitalic';
     src: url(assets/font/BaskervilleItalic/BaskervilleItalic.ttf) format("opentype");
 }



 h1 {
     color: #369;
     font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
     font-size: 250%;
 }

 html,
 body {
     font-family: "QuickSandMedium", sans-serif;
     font-size: 12px;
     height: 100%;
     margin: 0px !important;
 }

 ul,
 li {
     font-family: "Roboto";
 }

 .make-quick-sand-medium {
     font-family: "QuickSandMedium";
 }

 @media (max-width:1200px) {
     .hidden-header {
         display: inline;
     }
 }

 @media (min-width:1200px) {
     .hidden-header {
         display: none;
     }

     .row-no-spacing {
         margin-right: 0px !important;
         margin-left: 0px !important;
     }

     .col-no-spacing {
         padding-left: 0px !important;
         padding-right: 0px !important;
     }

     .col-left-no-space {
         padding-left: 0px !important;
     }

     .r-p-5 {
         padding-right: 5px !important;
     }

     .r-p-8 {
         padding-right: 8px !important;
     }

     .r-p-1 {
         padding-right: 1px !important;
     }

     .r-p-9 {
         padding-right: 9px;
     }

     .t-p-11 {
         padding-top: 11px;
     }

     .b-p-10 {
         padding-bottom: 10px;
     }

     .l-p-0 {
         padding-left: 0px !important;
     }

     .r-p-10 {
         padding-right: 10px !important;
     }

     .t-m-7 {
         margin-top: 7px;
     }

     .r-p-0 {
         padding-right: 0px;
     }

     .l-p-11 {
         padding-left: 11px;
     }

     .t-p-0 {
         padding-top: 0px;
     }

     .b-p-0 {
         padding-bottom: 0px;
     }

     .l-p-27 {
         padding-left: 27px;
     }

     .t-p-10 {
         padding-top: 10px;
     }

     .b-l-r-n {
         border-left: 0px;
         border-radius: 0px;
     }

     .b-c-t {
         border-color: transparent !important;
         border-width: 0px !important;
     }

     .b-c-t-i {
         border-top: 1px solid black;
         border-right: 1px solid black;
         border-left: 1px solid black;
     }

     .b-t-r-1 {
         border-top-left-radius: 1px !important;
         border-top-right-radius: 1px !important;
     }
 }

 .profile-image {
     width: 100px;
     height: 83px;
 }

 .profile-image-inbox {
     width: 100%;
     height: 96px;
     box-shadow: 0 1px 0px 1px rgba(0, 0, 0, .05);
 }

 .my-title {
     background: #feffff;
     border-top-left-radius: 2px !important;
     color: #000000 !important;
     border-top-right-radius: 2px !important;
 }

 .remove-default-list-style {
     list-style: none !important;
 }

 .grid-header {
     font-size: 11px;
     font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
     font-weight: 300;
     vertical-align: middle;
     padding: 5px;
     color: white;
     background-color: #cecece;
     -webkit-box-shadow: 0px 1px 1px 0px #646f74;
     -moz-box-shadow: 0px 1px 1px 0px #646f74;
     box-shadow: 0px 1px 1px 0px #646f74;
     margin-bottom: 5px;
 }

 .r-p-0 {
     padding-right: 0px !important;
 }

 .l-p-0 {
     padding-left: 0px !important;
 }

 .grid-footer {
     font-size: 11px;
     font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
     font-weight: 300;
     /* padding: 5px; */
     color: #08abbe;
     background-color: white;
     /* margin-top: 5px; */
     /* line-height: 30px; */
     border-bottom: 2px solid #44cdff;
 }

 .grid {
     width: 100%;
 }

 .blue-panel {
     background-color: White;
     height: 100%;
     width: 100%;
     border-top-color: #2CBDF9;
     border-top-style: Solid;
     border-top-width: 5px;
     vertical-align: Top;
     border-right: 1px solid #A8A8A8;
     border-left: 1px solid #A8A8A8;
     border-bottom: 1px solid #A8A8A8;
     padding: 12px 12px 12px 14px;
     margin-top: 5px;
     border-radius: 3px;
 }

 .blue-panel-addon {
     background-color: White;
     height: 100%;
     width: 100%;
     /* border-top-color: #44cdff;
     border-top-style: Solid;
     border-top-width: 2px;
     vertical-align: Top; */
 }

 .center-image-inside-div {
     width: 100%;
     margin: 0 auto;
 }

 .my-custom-panel {
     box-shadow: 0px 0px 0px 1px rgb(227, 230, 231) !important;
     border: 0px solid transparent !important;
     border-radius: 2px !important;
 }

 .my-form-control {
     display: block;
     width: 100%;
     height: 34px;
     padding: 6px 12px;
     font-size: 14px;
     line-height: 1.42857143;
     color: #555;
     background-color: #fff;
     background-image: none;
     border: 1px solid #48c6d8;
     border-radius: 2px;
     box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
     transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
     margin-bottom: 5px;
 }

 .bring-item-to-bottom {
     margin-top: 14%;
 }

 .mat-radio-button.mat-accent .mat-radio-inner-circle {
     background-color: #38daff;
 }

 .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
     border-color: #4ec7d4;
 }

 .l-p-5 {
     padding-left: 0.313em;
 }

 .l-p-15 {
     padding-left: 1em;
 }

 .cover-panel {}

 .advanced-search-button {
     width: 100%;
 }

 .m-t-10 {
     margin-top: 10px;
 }

 .custom-hr {
     border-bottom: 1px solid rgba(0, 0, 0, .42);
     width: 100%;
     margin-bottom: 5px;
 }

 .heading-hr {
     border-bottom: 1px solid rgba(0, 0, 0, .42);
     width: 26%;
     margin-bottom: 5px;
 }

 .example-full-width {
     width: 100%;
 }

 .select-font {
     font-size: 14px;
     font-family: "Roboto", sans-serif !important;
 }

 .remove-bold label {
     font-weight: 100 !important;
 }

 .m-t-8-m {
     margin-top: -8px;
 }

 .m-t-6-m {
     margin-top: -6px;
 }

 .h-c {
     color: #546E7A;
 }

 .b-p-5 {
     padding-bottom: 5px;
 }

 .remove-padding {
     padding: 0px !important;
 }

 .remove-border {
     border: 0px !important;
 }

 .panel-plus {
     box-shadow: 0px 1px 0px 1px rgba(0, 0, 0, .05) !important;
 }

 .mat-tab-label {
     min-width: 100px !important;
 }

 .hyperlink-button {
     background: none !important;
     color: inherit;
     border: none;
     padding: 0 !important;
     font: inherit;
     border-bottom: 1px solid #444;
     cursor: pointer;
 }

 .hyperlink-button:focus {
     outline: 0;
 }

 .top-panel {
     width: 100%;
     height: 100px;
 }

 .left-top-space {
     width: 7.5%;
     min-height: 100px;
     float: left;
 }

 .middle-top-space {
     width: 85%;
     float: left;
     min-height: 100px;
 }

 .top-panel-right-logo {
     width: 60%;
     float: right;
 }

 .top-panel-left-logo {
     width: 100%;
 }

 .right-top-space {
     width: 7.5%;
     float: left;
     min-height: 100px;
 }

 .public-background {
     min-height: 760px;
 }

 .top-panel {
     width: 100%;
     height: 100px;
 }

 .left-space {
     width: 7.5%;
     min-height: 760px;
     float: left;
 }

 .right-space {
     width: 7.5%;
     float: left;
     min-height: 760px;
 }

 .middle-space {
     width: 85%;
     float: left;
     min-height: 760px;
     -webkit-box-shadow: 0px 0px 10px 1px rgba(173, 175, 243, 0.46);
     box-shadow: 0px 0px 10px 1px rgba(173, 175, 243, 0.46);
 }

 .left-top-space {
     width: 7.5%;
     min-height: 100px;
     float: left;
 }

 .right-top-space {
     width: 7.5%;
     float: left;
     min-height: 100px;
 }

 .middle-top-space {
     width: 85%;
     float: left;
     min-height: 100px;
 }

 .top-panel-right-logo {
     width: 60%;
     float: right;
 }

 .top-panel-left-logo {
     width: 100%;
 }

 .contentpresent {
     width: 100%;
     min-height: 629px;
 }

 .float-left {
     float: left;
 }

 .label-quick-search {
     vertical-align: middle;
     text-align: center;
     padding-top: 1%;
     padding-right: 4%;
 }

 .ridge {
     height: 10px;
     width: 100%;
 }

 .full-col {
     margin-left: 0px !important;
     margin-right: 0px !important;
     padding-left: 0px !important;
     padding-right: 0px !important;
 }

 .full-col-right {
     margin-right: 0px !important;
     padding-right: 0px !important;
 }

 .full-col-left {
     margin-left: 0px !important;
     padding-left: 0px !important;
 }

 .border-it {
     border-right: 0.04rem #000000 solid;
 }

 .kill-padding {
     padding-left: 0px !important;
     padding-right: 0px !important;
 }

 .barelist {
     display: flex;
     flex-direction: column;
     width: 100%;
     /* height: 100%; */
 }

 .barelist-full-height {
     display: flex;
     flex-direction: column;
     width: 100%;
     height: 100%;
 }


 .standard-font {
     font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
     font-size: 12px;
 }

 .thin-hr {
     margin-top: 0rem;
     margin-bottom: 01rem;
     border: 0;
     border-top: 1px solid rgba(0, 0, 0, 0.1);
     width: 100%;
 }

 .my-hr {
     margin-top: 0rem;
     margin-bottom: 01rem;
     border: 0;
     border-top: 1px solid rgba(0, 0, 0, 0.1);
     width: 100%;
 }

 .super-input-box {
     display: block;
     width: 100%;
     padding: .375rem .75rem;
     font-size: 1rem;
     line-height: 1.5;
     color: #495057;
     background-color: #fff;
     background-clip: padding-box;
     border-radius: 1px !important;
     border: 0.7px solid #e1e7ec !important;
     outline: none;
 }

 .my-textbox {
     display: block;
     width: 100%;
     padding: .375rem .75rem;
     font-size: 1rem;
     line-height: 1.5;
     color: #495057;
     background-color: #fff;
     background-clip: padding-box;
     border-radius: 2px !important;
     border: 0.7px solid #e1e7ec !important;
     box-shadow: 0px 0px 4px 0px rgb(216, 216, 216);
 }

 .p-t-5 {
     padding-top: 5px !important;
 }

 .p-b-5 {
     padding-bottom: 5px !important;
 }

 .p-l-5 {
     padding-left: 5px;
 }

 .m-l-5 {
     margin-left: 5px;
 }

 .p-r-5 {
     padding-right: 5px !important;
 }

 .p-r-20 {
     padding-right: 20px !important;
 }

 .kill-right-spacing {
     margin-right: 0px !important;
     padding-right: 0px !important;
 }

 .kill-left-spacing {
     margin-left: 0px !important;
     padding-left: 0px !important;
 }

 .remove-exptra-padding {
     margin: 0px !important;
     padding: 0px !important;
 }



 .main-title {
     color: #5B5B5B;
     font-family: "Roboto", sans-serif;
     font-size: 23px;
     padding-left: 1.25rem;
     padding-top: 0.625rem;
 }

 .sub-title {
     color: #5B5B5B;
     font-family: "Roboto", sans-serif;
     font-size: 16px;
 }

 .offer-icon {
     width: 81px;
     height: 81px;
 }

 ::-webkit-scrollbar {
     width: 5px;
 }

 ::-webkit-scrollbar-track {
     background: black;
     border: 2px solid transparent;
     background-clip: content-box;
 }

 ::-webkit-scrollbar-thumb {
     background: #ffffff;
     border: 1px solid black;
 }


 #wrap>.container {
     padding-top: 60px;
 }

 .my-great-select {
     width: 100%;
     border-radius: 2px;
     height: 28px;
     border: 0.5px solid #a5a2a2;
 }

 .my-horizontal-list {
     list-style-type: none;
     margin: 0;
     padding: 0;
 }

 .my-horizontal-list li {
     float: left;
     display: block;
 }

 .community-rd {
     margin-top: -2px;
     padding-left: 10px;
 }

 .my-card {
     background-color: white !important;
     padding: 5px;
     border-radius: 0px !important;
     border: 0px !important;
     -webkit-box-shadow: 0px 0px 1px 1px #979ca8;
     box-shadow: 0px 0px 7px 5px #ebedf1;
 }

 .card {
     position: relative;
     display: -ms-flexbox;
     display: flex;
     -ms-flex-direction: column;
     flex-direction: column;
     min-width: 0;
     word-wrap: break-word;
     background-color: #fff;
     background-clip: border-box;
     border: 1px solid rgba(0, 0, 0, .125);
     border-radius: .25rem;
 }

 .spacer {
     width: 100%;
     margin: auto;
     height: 5px;
 }

 .heading {
     background-color: White;
     color: #818181;
     display: inline-block;
     left: 9px;
     line-height: 16px;
     padding: 0px 3px 0px 3px;
     position: relative;
     font-family: "Roboto";
     font-size: 12px;
 }

 .subheading {
     background-color: White;
     color: #2f54bf;
     position: absolute;
     font-family: "Roboto";
     font-size: 12px;
     font-weight: 800;
     bottom: 27%;
 }

 .bigspace {
     width: 100%;
     margin: auto;
     height: 7px;
 }

 .my-payment-gateway-image {
     height: 57px;
     width: 57px;
 }


 .my-custom-button {
     border-radius: 3px;
 }

 .panel {
     margin-bottom: 20px;
     background-color: #fff;
     border: none !important;
     border-radius: 2px;
     box-shadow: 0px 0px 0.5px 0.5px rgba(179, 179, 179, 0.91);
 }

 .panel-default {
     border-color: #ddd;
 }

 .my-title {
     border-top-left-radius: 2px !important;
     color: #000000 !important;
     border-top-right-radius: 2px !important
 }

 .panel-body {
     padding: 15px;
 }

 .panel-default>.panel-heading {
     border-color: #ddd;
     padding-top: 10px;
     padding-right: 15px;
     padding-bottom: 10px;
     padding-left: 15px;
     border-bottom: 1px solid transparent;
 }

 .panel-title {
     margin-top: 0;
     margin-bottom: 0;
     font-size: 15px;
     color: inherit;
     font-family: "Roboto", sans-serif;
 }

 .list-inline {
     padding-left: 0;
     list-style: none;
 }

 .e-grid .e-rowcell,
 .e-grid .e-detailrowcollapse,
 .e-grid .e-detailrowexpand {
     color: #000;
     opacity: 1 !important;
 }

 .super-button {
     border: 1px solid #15aeec;
     border-radius: 2px;
     font-size: 9px;
     padding-left: 12px;
     padding-top: 2px;
     padding-bottom: 2px;
     padding-right: 12px;
     text-decoration: none;
     display: inline-block;
     font-weight: bold;
     color: #FFFFFF;
     background-color: #49c0f0;
     background-image: linear-gradient(to bottom, #49c0f0, #2CAFE3);
     font-family: "Raleway", sans-serif;
 }

 .super-button:hover {
     border: 1px solid #1090c3;
     background-color: #1ab0ec;
     background-image: -webkit-gradient(linear, left top, left bottom, from(#1ab0ec), to(#1a92c2));
     background-image: -webkit-linear-gradient(top, #1ab0ec, #1a92c2);
     background-image: -moz-linear-gradient(top, #1ab0ec, #1a92c2);
     background-image: -ms-linear-gradient(top, #1ab0ec, #1a92c2);
     background-image: -o-linear-gradient(top, #1ab0ec, #1a92c2);
     background-image: linear-gradient(to bottom, #1ab0ec, #1a92c2);
     filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#1ab0ec, endColorstr=#1a92c2);
 }

 .del-icon {
     width: 19px;
     height: 16px;
 }

 .align-button {
     display: table-cell;
     vertical-align: middle;
     text-align: center;
     height: 139px;
 }

 .full-width {
     width: 100%;
 }

 .thumbnail-image {
     -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
     -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
     box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
     padding: 7px;
     border-radius: 3px;
     cursor: pointer;
 }

 .thumbnail-image-2 {
     -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
     -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
     box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
     padding: 7px;
     border-radius: 3px;
 }

 .custom-button {
     border-radius: 2px !important;
 }

 .my-register-button {
     border-radius: 2px !important;
 }

 .validation-buddy {
     color: #e24c4c;
     font-size: 0.9rem;
     font-family: inherit;
     padding-top: 5px;
 }

 .rc-ico {
     width: 44px;
     height: 25px;
     margin-right: 5px;
     padding-left: 5px;
     padding-right: 5px;
     cursor: pointer;
 }

 .action-box {
     display: flex;
     flex-direction: row;
 }

 .ico-1 {
     flex-basis: 33%;
 }

 .ico-2 {
     flex-basis: 33%;
 }

 .ico-3 {
     flex-basis: 33%;
 }

 .ibo-1 {
     flex-basis: 12.5%;
 }

 .ibo-2 {
     flex-basis: 12.5%;
 }

 .ibo-3 {
     flex-basis: 12.5%;
 }

 .ibo-4 {
     flex-basis: 12.5%;
 }

 .i5o-1 {
     flex-basis: 20%;
     padding-bottom: 10px;
 }

 .i5o-2 {
     flex-basis: 20%;
     padding-bottom: 10px;
 }

 .i5o-3 {
     flex-basis: 20%;
     padding-bottom: 10px;
 }

 .i5o-4 {
     flex-basis: 20%;
     padding-bottom: 10px;
 }

 .i5o-5 {
     flex-basis: 20%;
 }

 .popup-buttons-accept {
     background-color: #3d91b9;
     border: none;
     font-size: 12px;
     box-shadow: 0px 0px 2px 1px #b7b5b5;
     text-transform: capitalize;
     padding-left: 15px;
     padding-right: 15px;
     padding-top: 3px;
     padding-bottom: 5px;
     border-radius: 1px;
     color: white;
 }

 .popup-buttons-cancel {
     background-color: white;
     border: none;
     font-size: 9px;
     box-shadow: 0px 0px 2px 1px #b7b5b5;
     text-transform: capitalize;
     padding-left: 15px;
     padding-right: 15px;
     padding-top: 5px;
     padding-bottom: 5px;
     border-radius: 1px;
 }

 .rc-ico {
     width: 44px;
     height: 25px;
     margin-right: 5px;
     padding-left: 5px;
     padding-right: 5px;
     cursor: pointer;
 }

 .action-box-2 {
     display: flex;
     flex-direction: column;
 }

 .rc-ito-2 {
     width: 1.725rem;
     height: 1.525rem;
     cursor: pointer;
 }

 .rc-ito-5 {
     width: 2.45rem;
     height: 2.45rem;
     cursor: pointer;
 }

 .rc-ito-3 {
     width: 1.725rem;
     height: 1.725rem;
     cursor: pointer;
     margin-bottom: 7px;
 }

 .rc-ito-3:hover {
     width: 1.725rem;
     height: 1.725rem;
     cursor: pointer;
     margin-bottom: 7px;
 }


 .horizontal-divs {
     display: flex;
     flex-direction: row;
 }

 .image-display-thumbnail {
     width: 14.3rem;
     height: 12.5rem;
 }

 .row {
     display: flex;
     flex-direction: row;
 }

 .row-feature {
     display: flex;
     flex-direction: row;
     justify-content: space-around;
 }

 .image-display {
     height: 65vh;
 }

 .vault-user-image {
     width: 100%;
     height: 100%;
 }

 .p-card-content-2 {
     display: flex;
     justify-content: center;
 }

 .bookmark-image {
     width: 100%;
     margin-bottom: 10px !important;
     height: 170px;
     cursor: pointer;
     border-radius: 0.75rem;
 }

 .bookmark-image-no-cursor {
     width: 176px;
     margin-bottom: 10px !important;
     height: 170px;
     border-radius: 0.75rem;
 }

 .profile-image-no-cursor {
     width: 176px;
     margin-bottom: 10px !important;
     height: 170px;
     border-radius: 0.75rem;
     margin-left: 0.5vw;
 }

 .my-horizontal-list2 {
     list-style-type: none;
     margin: 0;
     padding: 0;
 }

 .my-horizontal-list2 li {
     float: left;
     display: block;
     margin-right: 10px;
 }

 .hyperlink-In-button {
     color: inherit;
     font: inherit;
     margin-right: 5px;
     text-transform: capitalize;
     font-family: 'QuickSandRegular';
     font-size: 12px !important;
     cursor: pointer;
     background-color: #ffffff;
     border-radius: 4px;
     border: 1px solid #b7adad;
     letter-spacing: 2px;
     position: relative;
     height: 34px;
     line-height: 21px;
     width: 100%;
     padding-left: 26px;
     padding-right: 26px;
 }

 .hyperlink-In-button:hover {
     color: inherit;
     font: inherit;
     margin-right: 5px;
     text-transform: capitalize;
     font-family: 'QuickSandRegular';
     font-size: 12px !important;
     cursor: pointer;
     background-color: #ffffff;
     border-radius: 4px;
     border: 1px solid #b7adad;
     letter-spacing: 2px;
     position: relative;
     height: 34px;
     line-height: 21px;
     width: 100%;
     padding-left: 26px;
     padding-right: 26px;
 }

 .close-btn {
     position: absolute;
     top: -3px;
     right: 2px;
     color: #852a4f;
     cursor: pointer;
     font-weight: bold;
     font-size: 16px;
 }

 .close-btn:hover {
     color: #ff0000;
 }

 .part-image {
     width: 170px;
     margin-right: 10px !important;
     height: 170px;
     cursor: pointer;
     border-radius: 0.75rem;
 }


 .no-value-text {
     font-family: 'QuickSandMedium';
     font-size: 9px;
     text-align: justify;
     height: 41px;
     line-height: 35px;
     border-radius: 3px;
     text-transform: capitalize;
     font-weight: 700;
     color: #328fe1;
     padding-left: 0.15vw;
 }

 .horizontal-list {
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     justify-content: start;
     gap: 15px;
 }

 .horizontal-list-with-out-wrap {
     display: flex;
     flex-direction: row;
     justify-content: start;
     gap: 15px;
 }

 .vertical-list {
     display: flex;
     flex-direction: column;
 }


 .bookmark-card {
     display: flex;
     flex-direction: column;
     box-shadow: 0px 0px 7px 0px #c1c1c194;
     border-radius: 3px;
     margin: 0px;
     padding: 25px;
 }

 .home-profile-image {
     width: 157px;
     height: 170px;
 }

 .thumbnail2 {
     padding: 10px;
     width: 201px;
     margin: auto;
     height: 285px;
     border-radius: 2px;
     box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
 }

 .applicant-image {
     border-bottom: 4px solid #d8d8d8;
     height: 200px;
     width: 180px;
     border: 1px solid #CCC;
     border-radius: 2px !important;
 }

 .table-container {
     padding-left: 0px !important;
     padding-right: 0px !important;
 }

 .table-container>div:nth-of-type(odd) {
     background: #142239;
     padding: 10px !important;
     color: white;
 }

 .table-container>div:nth-of-type(even) {
     padding: 10px !important;
     background-color: #2af10624;
     color: #000000;
 }

 .table-container-2 {
     padding-left: 0px !important;
     padding-right: 0px !important;
 }

 .table-container-2>div {
     background: #142239;
     padding: 10px !important;
     color: white;
 }

 .table-container-3 {
     background-color: #315D84;
     padding: 10px !important;
     color: white;
 }

 .p-selectbutton .p-button.p-highlight {
     background: linear-gradient(#fff600, #e3db00, #fff600 54%, #f1e900 89%, #f1e900 0%);
     border-color: #e7d111;
     color: #8f9f8d;
 }

 .p-selectbutton .p-button.p-highlight:hover {
     background: linear-gradient(#fff840, #fffc6d 11%, #ffec50 46%, #ffeb60 54%, #e7d111);
     border-color: #e7d111;
     color: #8f9f8d;
 }

 .cb {
     color: #8f9f8d;
     background: linear-gradient(#e7d111, #ffeb60, #ffec50 54%, #fffc6d 89%, #fff840 0%);
     border: 1px solid #e7d111;
     padding: 0.5rem 1rem;
     font-size: 1rem;
     transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
     border-radius: 3px;
     outline-color: transparent;
 }

 .cb:hover {
     color: #8f9f8d;
     background: linear-gradient(#fff840, #fffc6d 11%, #ffec50 46%, #ffeb60 54%, #e7d111);
     border: 1px solid #e7d111;
     padding: 0.5rem 1rem;
     font-size: 1rem;
     transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
     border-radius: 3px;
     outline-color: transparent;
 }

 .cb-red {
     color: #e7ede6;
     background: linear-gradient(#d793936b, #996b6b75, #cf9f9f 54%, #b985857a 89%, #bb7d7d96);
     border: 1px solid #b99c9c96;
     padding: 0.5rem 1.6rem;
     font-size: 0.9rem;
     transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
     border-radius: 3px;
     outline-color: transparent;
     font-family: 'QuickSandMedium';

     /* // iPhone XR */
     @media (max-width: 414px) and (max-height: 896px) and (orientation: portrait) {
         width: 100%;
         margin-bottom: 0.3rem;
         justify-content: center;
     }
 }

 .cb-red:hover {
     color: #e7ede6;
     background: linear-gradient(#bb7d7d96, #b985857a 20%, #cf9f9f 46%, #996b6b75 80%, #d793936b);
     border: 1px solid #b99c9c96;
     padding: 0.5rem 1.6rem;
     font-size: 0.9rem;
     transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
     border-radius: 3px;
     outline-color: transparent;
     font-family: 'QuickSandMedium';

     /* // iPhone XR */
     @media (max-width: 414px) and (max-height: 896px) and (orientation: portrait) {
         width: 100%;
         margin-bottom: 0.3rem;
         justify-content: center;
     }
 }

 .cb-green {
     color: #505050;
     background: linear-gradient(#0de0e7, #11d7cd, #11efe9 54%, #21e3c8 89%, #20dfe9 0%);
     border: 1px solid #03d5dd;
     padding: .5rem 1rem;
     font-size: 0.8rem;
     transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
     border-radius: 3px;
     outline-color: transparent;
     font-family: 'QuickSandBold';
     letter-spacing: 3px;
 }

 .cb-green:hover {
     color: #505050;
     background: linear-gradient(#20dfe9, #21e3c8 11%, #11efe9 46%, #11d7cd 54%, #0de0e7);
     border: 1px solid #03d5dd;
     padding: 0.5rem 1rem;
     font-size: 0.8rem;
     transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
     border-radius: 3px;
     outline-color: transparent;
     font-family: 'QuickSandBold';
     letter-spacing: 3px;
 }


 .cb-blue {
     color: #ffffffc9;
     background: linear-gradient(#168fd5, #34a3bd 20%, #44aac1 50%, #218eb3 80%, #2e7d9d);
     border: 1px solid #2e7d9d;
     padding: 0.5rem 1rem;
     font-size: 0.9rem;
     transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
     border-radius: 3px;
     outline-color: transparent;
     font-family: 'QuickSandMedium';
     font-weight: 100;

     /* // iPhone XR */
     @media (max-width: 414px) and (max-height: 896px) and (orientation: portrait) {
         width: 100%;
         margin-bottom: 0.3rem;
         justify-content: center;
     }
 }

 .cb-blue:hover {
     color: #ffffffc9;
     background: linear-gradient(#2e7d9d, #218eb3 20%, #44aac1 50%, #34a3bd 80%, #168fd5);
     border: 1px solid #168fd5;
     padding: 0.5rem 1rem;
     font-size: 0.9rem;
     transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
     border-radius: 3px;
     outline-color: transparent;
     font-family: 'QuickSandMedium';
     font-weight: 100;

     /* // iPhone XR */
     @media (max-width: 414px) and (max-height: 896px) and (orientation: portrait) {
         width: 100%;
         margin-bottom: 0.3rem;
         justify-content: center;
     }
 }

 ::ng-deep .ngx-pagination a,
 .ngx-pagination button {
     color: #07a7a7;
     display: block;
     padding: .1875rem .625rem;
     border-radius: 0;
 }


 /* For styles.css */
 .p-button.custom-tag-button,
 :root .p-button.custom-tag-button {
     background: #fff !important;
     border: 1px solid #dee2e6 !important;
     color: #495057 !important;
     padding: 0.35rem 0.75rem !important;
     font-weight: normal !important;
     border-radius: 4px !important;
     min-width: 100px !important;
     display: flex !important;
     align-items: center;
     justify-content: space-between;
     margin: 0.25rem;
     transition: all 0.2s;
     position: relative !important;
 }

 .p-button.custom-tag-button:hover,
 :root .p-button.custom-tag-button:hover {
     background: #f8f9fa !important;
 }

 .p-button.custom-tag-button .button-text,
 :root .p-button.custom-tag-button .button-text {
     flex: 1;
     text-align: left;
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap;
     margin-right: 1rem;
 }

 .p-button.custom-tag-button .close-icon,
 :root .p-button.custom-tag-button .close-icon {
     font-size: 0.75rem;
     cursor: pointer;
     position: absolute;
     right: 0.5rem;
     top: 0.35rem;
     color: #9ca3af;
     transition: color 0.2s ease;
 }

 .p-button.custom-tag-button .close-icon:hover,
 :root .p-button.custom-tag-button .close-icon:hover {
     color: #dc2626;
 }

 .like-text {
     margin-top: -3px;
     margin-left: 2px;
 }

 .custom-tooltip .p-tooltip-text {
     font-size: 14px;
     padding: 8px 16px;
     /* Combined padding for top/bottom and left/right */
     color: black;
     /* White text for contrast */
     border-radius: 5px;
     /* Rounded corners */
     /* background-image: linear-gradient(to right top, #845ec2, #009efa, #00d2fc, #4ffbdf, #77afe3); */

     background-color: #a2fffe61;
     /* Neon Glow gradient */
     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
     /* Subtle shadow for depth */
     border: none;
     /* Remove default border */
     min-width: 140px;
     /* Set a minimum width for the tooltip */
     text-align: center;
     /* Prevent text from breaking into multiple lines */
     transition: opacity 0.3s ease-in-out;

     font-family: "QuickSandRegular";
     text-transform: capitalize;
 }

 .other-prifle-title {
     font-family: 'QuickSandMedium';
     font-size: 12px;
     font-weight: 600;
     color: #0091cb;
 }


 .zoom-icon {
     transition: transform 0.3s ease;
     /* Smooth transition for the zoom effect */
 }

 .zoom-icon:hover {
     transform: scale(1.4);
     /* Zoom in by 20% on hover */
 }

 .add-cursor {
     cursor: pointer;
 }